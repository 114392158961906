import React from 'react'
import { Link } from "react-router-dom";

function TeamsComponents() {
    return (
        <section className="teams" id="teams">
            <div className="max-width">
                <h2 className="title">My teams</h2>
                <div className="carousel owl-carousel">
                    <div className="card">
                        <div className="box">
                            <img src="images/profile.jpg" alt="" />
                            <div className="text">Aditya Kumar</div>
                            <div className="job">Website Designer/Developer</div>
                            <p className="des">Hello Everyone, I am a Web Designer and Developer.</p>
                            <div className="content">
                                <div className="top">
                                    <div className="media-icons">
                                        <Link to="https://www.facebook.com/profile.php?id=100027830802048" target="_blank"><i
                                            className="fab fa-facebook-f"></i></Link>
                                        <Link to="https://twitter.com/Adityak23482293" target="_blank"><i
                                            className="fab fa-twitter"></i></Link>
                                        <Link to="https://instagram.com/aditya_kumar_6414119?igshid=MzNlNGNkZWQ4Mg==" target="_blank"><i
                                            className="fab fa-instagram"></i></Link>
                                        <Link to="https://api.whatsapp.com/send?phone=+919708268593" target="_blank"><i
                                            className="fab fa-whatsapp"></i></Link>
                                        <Link to="https://youtube.com/@FeelTheEmotions1?sub_confirmation=1"
                                            target="_blank"><i className="fab fa-youtube"></i></Link>
                                        <Link to="https://github.com/CodeWithAdityaKumar" target="_blank"><i
                                            className="fab fa-github"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default TeamsComponents
