import React from 'react'
import AboutComponents from '../components/About'

function About() {
  return (
    <>
    <AboutComponents />
    </>
  )
}

export default About
