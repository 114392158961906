import React from 'react'
import TeamsComponents from '../components/Teams'

function Teams() {
  return (
   
    <>
    <TeamsComponents/>
    </>

  )
}

export default Teams
