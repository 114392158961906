import React from 'react'
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
        <div class="content">
            <div class="top">
                <div class="logo-details">
                    <i class="fab fa-slack"></i>
                    <span class="logo_name">AdityaKumar.</span>
                </div>
                <div class="media-icons">
                    <Link to="https://www.facebook.com/profile.php?id=100027830802048" target="_blank"><i
                            class="fab fa-facebook-f"></i></Link>
                    <Link to="https://twitter.com/Adityak23482293" target="_blank"><i
                            class="fab fa-twitter"></i></Link>
                    <Link to="https://instagram.com/aditya_kumar_6414119?igshid=MzNlNGNkZWQ4Mg==" target="_blank"><i
                            class="fab fa-instagram"></i></Link>
                    <Link to="https://api.whatsapp.com/send?phone=+919708268593" target="_blank"><i
                            class="fab fa-whatsapp"></i></Link>
                    <Link to="https://youtube.com/@FeelTheEmotions1?sub_confirmation=1"
                        target="_blank"><i class="fab fa-youtube"></i></Link>
                    <Link to="https://github.com/CodeWithAdityaKumar" target="_blank"><i class="fab fa-github"></i></Link>
                </div>
            </div>
            <div class="link-boxes">
                <ul class="box">
                    <li class="link_name">Company</li>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/contact">Contact us</Link></li>
                    <li><Link to="/about">About us</Link></li>
                    <li><Link to="/">Get started</Link></li>
                </ul>
                <ul class="box">
                    <li class="link_name">Services</li>
                    <li><Link to="/contact">App design</Link></li>
                    <li><Link to="/contact">Web design</Link></li>
                    <li><Link to="/contact">Web Development</Link></li>
                    <li><Link to="/contact">App Development</Link></li>
                </ul>
                <ul class="box">
                    <li class="link_name">Account</li>
                    <li><Link to="/jehrbgjerb">Profile</Link></li>
                    <li><Link to="/jehrbgjerb">My account</Link></li>
                    <li><Link to="/jehrbgjerb">Prefrences</Link></li>
                    <li><Link to="/jehrbgjerb">Purchase</Link></li>
                </ul>
                <ul class="box">
                    <li class="link_name">Courses</li>
                    <li><Link to="#">HTML & CSS</Link></li>
                    <li><Link to="#">JavaScript</Link></li>
                    <li><Link to="#">React JS</Link></li>
                    <li><Link to="#">Node JS</Link></li>
                </ul>
                <ul class="box input-box">
                    <li class="link_name">Subscribe</li>
                    <li><input type="text" placeholder="Enter your email" /></li>
                    <li><input type="button" value="Subscribe" /></li>
                </ul>
            </div>
        </div>
        <div class="bottom-details">
            <div class="bottom_text">
                <marquee scrollamount="8">
                    <p size="5"><span>Created By Aditya Kumar | <span class="far fa-copyright"></span> <script>
                        let year = new Date().getFullYear();
                        document.write(year)
                    </script> All rights
                            reserved.</span></p>
                </marquee>
            </div>
        </div>
    </footer>
  )
}

export default Footer
