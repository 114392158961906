import React from 'react'
import { Link } from "react-router-dom";


function ProjectsComponents() {
    return (
        <section className="services projects" id="services">
            <div className="max-width">
                <h2 className="title">My Projects</h2>
                <div className="serv-content">
                    <div className="projects-posts">



                        <div className="projects-post">
                            <div className="box">
                                <Link to="https://endeavoursliet.vercel.app/">
                                    <img src="/images/Projects_Posts/Endeavour.png" alt='poster' />
                                    <div className="cloneSiteUrl">Endeavor : SLIET Clone <br/>using ReactJS</div>
                                </Link>
                                <Link to="https://endeavoursliet.in/">
                                    <div className="mainSiteUrl">Main Website</div>
                                </Link>
                            </div>
                        </div>

                        <div className="projects-post">
                            <div className="box">
                                <Link to="https://anujtiwariat.vercel.app/">
                                    <img src="/images/Projects_Posts/Anuj_Tiwari_A-T.png" alt='poster' />
                                    <div className="cloneSiteUrl">Anuj Tiwar/A.T using HTML, CSS and JS</div>
                                </Link>
                                <Link to="https://anujtiwariat.vercel.app/">
                                    <div className="mainSiteUrl">Main Website</div>
                                </Link>
                            </div>
                        </div>

                        <div className="projects-post">
                            <div className="box">
                                <Link to="https://akchatapp-3qsl.onrender.com/">
                                    <img src="/images/Projects_Posts/AKChatApp.png" alt='poster' />
                                    <div className="cloneSiteUrl">AKCHAT APP <br/> using NodeJS     </div>
                                </Link>
                                <Link to="https://akchatapp-3qsl.onrender.com/">
                                    <div className="mainSiteUrl">Main Website</div>
                                </Link>
                            </div>
                        </div>

                        <div className="projects-post">
                            <div className="box">
                                <Link to="https://akmovies4upro.vercel.app/">
                                    <img src="/images/Projects_Posts/AKMovies4UPro.png" alt='poster' />
                                    <div className="cloneSiteUrl">AKMovies4UPro App using HTML, CSS and JS</div>
                                </Link>
                                <Link to="https://akmovies4upro.vercel.app/">
                                    <div className="mainSiteUrl">Main Website</div>
                                </Link>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProjectsComponents
