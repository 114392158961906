import React from 'react'
import { Link } from "react-router-dom";

function Hero() {
  return (
    <>
      <section className="home" id="home">

<div className="max-width">
    <div className="home-content">
        <div className="text-1">Hello, My Name is</div>
        <div className="text-2">Aditya Kumar</div>
        <div className="text-3">And I'm a <span className="typing"></span></div>
        <Link to="/contact">Hire me</Link>
    </div>
</div>
</section>
    </>
  )
}

export default Hero
