import React from 'react'
import Hero from '../components/Hero'
import AboutComponents from '../components/About'
import ServicesComponents from '../components/Services'
import SkillsComponents from '../components/Skills'
import TeamsComponents from '../components/Teams'
import ContactComponents from '../components/Contact'

function Home() {
  return (
    <>
      <Hero/>
      <AboutComponents/>
      <ServicesComponents/>
      <SkillsComponents/>
      <TeamsComponents/>
      <ContactComponents/>

    </>
  )
}

export default Home
