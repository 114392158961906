import React from 'react'
import { Link } from "react-router-dom";

function AboutComponents() {
  return (
    <>
    <section className="about" id="about">
    <div className="max-width">
        <h2 className="title">About me</h2>
        <div className="about-content">
            <div className="column left">
                <img src="/images/profile.jpg" alt="" />
            </div>
            <div className="column right">
                <div className="text">I'm Aditya and I'm a <span className="typing-2"></span></div>
                <p>I'm always looking for new and challenging projects to work on, and I'm always eager to learn new things. I'm a creative and innovative thinker, and I'm always looking for new ways to solve problems. I'm also a hard worker and I'm always willing to put in the extra effort to get the job done. I'm excited to share my work with you, and I hope you'll find it as inspiring as I do.</p>
                <Link to="/about" target='_blank'>Download CV</Link>
            </div>
        </div>
    </div>
</section>
</>
  )
}

export default AboutComponents
