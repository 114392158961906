import React from 'react'
import ServicesComponents from '../components/Services'

function Services() {
  return (
    <>
    <ServicesComponents/>
    </>
  )
}

export default Services
