import React from 'react'
// import SkillsComponents from '../components/Skills'

function Skills() {
  return (
    
    <>
    <section className="skills" id="skills">
        <div className="max-width">
            <h2 className="title">My skills</h2>
            <div className="skills-content">
                <div className="column left">
                     
                    <div className="text">My creative skills & experiences.</div>
                    <p>I have a strong understanding of HTML and CSS, the two languages that form the basis of all web pages. I can use these languages to create visually appealing and functional web pages.  I am proficient in JavaScript, a programming language that allows me to add interactivity and functionality to web pages. I can use JavaScript to create animations, slideshows, and other interactive elements. I have a strong understanding of the principles of user experience (UX) and user interface (UI) design. I can use these principles to create web pages that are easy to use and understand. I am proficient in responsive design, which allows web pages to adapt to different screen sizes. This ensures that your web pages will look good on all devices, from smartphones to tablets to laptops. I have experience in back-end development, which is the process of creating the server-side code that powers a web application. I can use programming languages like PHP, Python, and Java to create back-end code. I have experience in working with databases, which are used to store data for web applications. I can use database languages like SQL to create and manage databases.</p>
                </div>
                <div className="column right">
                    <div className="bars">
                        <div className="info">
                            <span>
                            <i className="fab fa-html5"></i>  HTML
                            </span>
                            <span>90%</span>
                        </div>
                        <div className="line html"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span># CSS</span>
                            <span>60%</span>
                        </div>
                        <div className="line css"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span><i className="fab fa-js"></i> JavaScript</span>
                            <span>50%</span>
                        </div>
                        <div className="line js"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span><i className="fab fa-php"></i> PHP</span>
                            <span>5%</span>
                        </div>
                        <div className="line php"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span><i className="iconify" data-icon="logos:mysql-icon"></i> MySQL</span>
                            <span>2%</span>
                        </div>
                        <div className="line mysql"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span><i className="fab fa-python"></i> PYTHON</span>
                            <span>10%</span>
                        </div>
                        <div className="line python"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span><i className="fab fa-linux"></i> LINUX</span>
                            <span>40%</span>
                        </div>
                        <div className="line linux"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span><i className="fab fa-java"></i> JAVA </span>
                            <span>0%</span>
                        </div>
                        <div className="line java"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span><i className="iconify" data-icon="logos:django-icon"></i> DJANGO</span>
                            <span>5%</span>
                        </div>
                        <div className="line django"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span> TERMUX</span>
                            <span>75%</span>
                        </div>
                        <div className="line termux"></div>
                    </div>
                    <div className="bars">
                        <div className="info">
                            <span><i className="fab fa-github"></i> GITHUB</span>
                            <span>60%</span>
                        </div>
                        <div className="line github"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
    
  )
}

export default Skills
