import React from 'react'

function ContactComponents() {

    const Framestyles = {
        border: 1,
        padding: "1px"
    };


    function handleSubmit(e) {

        alert("Please Wait a Moment a confirmation message will pop up.............");

        const scriptURL =
            "https://script.google.com/macros/s/AKfycbwBSTPhRG8qw4PIuUAR6P5DwCT-ZsFUNp4ykqg9RRO-G1jlckvRELMXVGqKeupyw5JEUw/exec";

        const form = document.forms["google-sheet"];

        e.preventDefault();
        fetch(scriptURL, { method: "POST", body: new FormData(form) })
            .then((response) => {
                alert("Thanks for Contacting us..! We Will Contact You Soon...");
                form.reset();
            }
            )
            .catch((error) => console.error("Error!", error.message));
    }




    return (
        <>
            <section className="contact" id="contact">
                <div className="max-width">
                    <h2 className="title">Contact me</h2>
                    <div className="contact-content">
                        <div className="column left">
                            <div className="text">Get in Touch</div>
                            <div className="contact-map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d188.66163063585591!2d85.32631673018447!3d26.453438327200544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ece73e0959598d%3A0xb674156155251b74!2sAshok%20kumar!5e1!3m2!1sen!2sin!4v1689216442455!5m2!1sen!2sin" width="100%" height="312px" style={Framestyles} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className="column right">
                            <div className="text">Message me</div>
                            <form onSubmit={handleSubmit} method='post' name='google-sheet'>
                                <div className="fields">
                                    <div className="field name">
                                        <input type="text" id="name" name='Name' placeholder="Name...." required />
                                    </div>
                                    <div className="field email">
                                        <input type="email" id="email" name='Email' placeholder="Email..." required />
                                    </div>
                                </div>
                                <div className="fields">
                                    <div className="field name">
                                        <input type="text" id="phone" name='Phone' placeholder="+97798624*****" required />
                                    </div>
                                    <div className="field email">
                                        <input type="text" id="address" name='Address' placeholder="Address..." required />
                                    </div>
                                </div>
                                <div className="field">
                                    <input type="text" id="subject" name='Subject' placeholder="Subject...." required />
                                </div>
                                <div className="field textarea">
                                    <textarea cols="30" rows="10" id="message" name='Message' placeholder="Message....." required></textarea>
                                </div>
                                <div className="button-area">
                                    <button type="submit" name='submit'>Send message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default ContactComponents
