import React from 'react'
import ContactComponents from '../components/Contact'

function Contact() {
  return (
    <>
    <ContactComponents/>
    </>
  )
}

export default Contact
