import React from 'react'
import ProjectsComponents from '../components/Projects'

function Projects() {
  return (
   <>
   <ProjectsComponents />
   </>
  )
}

export default Projects
