import React from 'react'

function ServicesComponents() {
    return (
        <section className="services" id="services">
        <div className="max-width">
            <h2 className="title">My services</h2>
            <div className="serv-content">
                <div className="card">
                    <div className="box">
                        <i className="fas fa-paint-brush"></i>
                        <div className="text">Web Design</div>
                        <p>I am a web designer with a passion for creating beautiful and functional websites. I have a strong understanding of design principles, and I am skilled in using a variety of web design tools. I am also familiar with the latest web technologies, and I am always up-to-date on the latest trends.
                        </p>
                    </div>
                </div>

                <div className="card">
                    <div className="box">
                        <i className="fas fa-chart-line"></i>
                        <div className="text">Web Developer</div>
                        <p>I am a web developer with a passion for creating high-quality, user-friendly websites. I have a strong understanding of the latest web technologies, and I am always looking for new ways to improve the way people interact with the web. I have experience in a variety of web development disciplines.
                        </p>
                    </div>
                </div>

                <div className="card">
                    <div className="box">
                        <i className="fas fa-code"></i>
                        <div className="text">Apps Design</div>
                        <p>I am a skilled app designer with a passion for creating user-friendly and visually appealing apps. I have a strong understanding of the principles of user experience (UX) and user interface (UI) design, and I am always looking for new ways to improve the way people interact with their devices.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ServicesComponents
