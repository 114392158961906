import React from 'react'
import './error.css'

function ErrorPage() {

    const Errorstyles = {
        border: 1,
        padding: "1px"
    };

  return (

    <>
    

    <section className="services error" id="services">
        <div className="max-width">
            <h2 className="title">404 - Error Page</h2>
            <div className="serv-content">
            <h2>Oops! The page you are looking for cannot be found.</h2>
      <p>Please try searching for the page or go back to the homepage.</p>
      <a href="/">Go to homepage</a>
            </div>
        </div>
    </section>

  </>
  )
}

export default ErrorPage
